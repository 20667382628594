@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* 
Valiables
*/
:root {
    --primary-color       : #2BAFE5;
    --secondary-color     : #CACED0;
    --text-color          : #2E2E2E;
    --secondary-text-color: white;
    --mobile-breakpoint   : "768px"
}

.App {}

body {
    font-family: 'Montserrat', sans-serif;
    font-size  : 15px;
}



h1,
h2,
h3,
h4 {
    font-weight: 800;
}

h1 {
    font-size: 25px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

.align-right {
    text-align: right !important;
}

a,
a:hover {
    color: var(--primary-color);
}

.App-logo {
    height        : 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height      : 100vh;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    font-size       : calc(10px + 2vmin);
    color           : white;
}

.App-link,
.link {
    color : #61dafb;
    cursor: pointer;
}

.container-fluid {
    padding-left : 0px;
    padding-right: 0px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
} 

.logo {
    align-items     : flex-end;
    display         : flex;
    padding-top     : 50px;
    padding-bottom  : 50px;
    position        : absolute;
    z-index         : 999;
    width           : 270px;
    background-color: #F8FAFD;
}

.sidebar {
    width   : 270px;
    height  : 100vh;
    position: fixed;
    top     : 0px;
    left    : 0px;
}

nav {
    padding-top     : 140px;
    width           : 270px;
    height          : 100vh;
    left            : 0px;
    top             : 0px;
    background-color: #F8FAFD;
    display         : inline-block;
    overflow        : scroll;
}

nav ul {
    list-style: none;
    margin    : 20px;
    padding   : 0px;
}

nav ul li {
    color         : #142945;
    list-style    : none;
    font-weight   : bold;
    padding-left  : 20px;
    padding-bottom: 30px;
    position      : relative;
}

nav ul li a {
    color          : #142945;
    list-style     : none;
    font-weight    : bold;
    text-decoration: none;
    transition     : 0.2s ease color;
}

nav ul li a:hover {
    text-decoration: none;
}

.red {
    color: var(--primary-color)
}

nav ul li.current_page:before {
    width                     : 11px;
    height                    : 11px;
    border-radius             : 100%;
    border-bottom-right-radius: 0px;
    background-color          : var(--primary-color);
    position                  : absolute;
    display                   : block;
    content                   : '';
    left                      : 0px;
    top                       : 4px;
}

.sidebar_right {
    width           : 100%;
    max-width       : 650px;
    height          : 100vh;
    position        : fixed;
    right           : -100%;
    left            : auto;
    top             : 0px;
    background-color: white;
    display         : inline-block;
    padding         : 40px;
    overflow        : scroll;
    transition      : all 0.5s ease;
    margin-right    : 0px;
    margin-left     : 0px;
    z-index         : 99;
    padding-top     : 90px;
}

.sidebar_right.show {
    right: 0;
}

.sidebar_overlay {
    width           : 100vw;
    height          : 100vh;
    background-color: rgba(0, 0, 0, 0);
    display         : block;
    position        : fixed;
    transition      : all 0.2s ease;
    top             : 0px;
    left            : 0px;
    z-index         : -1;
}

.sidebar_overlay.show {
    background-color: rgba(0, 0, 0, 0.7);
    z-index         : 99;
}

.content_wrapper {
    width         : calc(100vw - 270px);
    display       : inline-block;
    position      : absolute;
    top           : 0px;
    left          : 270px;
    padding       : 30px;
    padding-bottom: 100px;
}

/* Login */

.login_wrapper {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100vh;
}

.login_container {
    display         : inline-block;
    padding         : 50px;
    width           : 90%;
    max-width       : 600px;
    background-color: #F8FAFD;
}

input {
    font-family: "Montserrat" !important;
}

.MuiTextField-root {
    width        : 100%;
    margin-bottom: 10px;
    font-family  : inherit;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom: 1px solid #142945 !important;
}

.MuiInput-underline.Mui-focused:before {
    border-bottom: 1px solid #142945 !important;
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-focused {
    color      : #142945 !important;
    opacity    : 0.57;
    font-family: inherit;
}

.MuiInputLabel-root {
    font-family: inherit !important;
}

.MuiInputBase-root {
    margin-bottom: 15px !important;
}

.MuiInputBase-input {
    color: #142945 !important;
}

.Mui-error,
.MuiFormLabel-root.Mui-focused.Mui-error {
    color  : var(--primary-color) !important;
    opacity: 1 !important;
}

.MuiInput-underline.Mui-error:before,
.MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid var(--primary-color) !important;
}

.MuiInputBase-root {
    width: 100%;
}

.MuiPopover-root {
    z-index    : 9999 !important;
    font-family: "Montserrat" !important;
}

label.MuiFormLabel-root {
    font-size: 13px;
}

.MuiTextField-root label {
    font-size: 15px !important;
}

label {
    margin-bottom: 0px;
    display      : block;
}

.input_container {
    width           : 100%;
    display         : inline-block;
    position        : relative;
    margin-bottom   : 20px;
    color           : var(--text-color);
    background-color: white;
}

.input_container .input_container_label {
    display   : inline-block;
    position  : relative;
    font-size : inherit;
    top       : 0px;
    transition: all 0.1s ease;
    left      : 0px;
    color     : var(--text-color);
    z-index   : 0;
    cursor    : text;
}

.input_container.error .input_container_label {
    color: #EE4042;
}

.input_container.error input {
    background-image   : url(./assets/icons/error.svg);
    background-repeat  : no-repeat;
    background-size    : 14px;
    background-position: right 18px center;
}

.input_container input,
.input_container textarea {
    width           : 100%;
    outline         : none;
    background-color: transparent;
    box-sizing      : border-box;
    display         : inline-block;
    font-size       : inherit;
    border          : 0px solid var(--primary-color);
    border-radius   : 5px;
    background-color: #EFF7FA;
    font-family     : inherit;
    padding         : 10px 12px;
}

.input_container textarea {
    overflow: hidden;
    resize  : none;
}

/* Predictions */
.predictions_popup {
    display                   : inline-block;
    position                  : absolute;
    top                       : 100%;
    left                      : 0px;
    background                : white;
    width                     : 100%;
    z-index                   : 99;
    padding                   : 10px;
    border-bottom-left-radius : 5px;
    border-bottom-right-radius: 5px;
    border-top                : 1px solid var(--primary-color);
    box-shadow                : 0px 0px 20px rgba(0, 0, 0, 0.16);
    max-height                : 300px;
    overflow                  : scroll;
}

.predictions_container {
    padding         : 10px;
    border-radius   : 5px;
    cursor          : pointer;
    background-color: transparent;
}

.predictions_container:hover {
    background: #EFF7FA;
}

.date_picker {
    padding-left: 50px !important;
}

.datetime_picker_button {
    height             : 42px;
    width              : 42px;
    position           : absolute;
    background-image   : url(./assets/icons/Calendar.svg);
    z-index            : 9;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 24px;
    cursor             : pointer;
    bottom             : 0px;
}

.time_picker_button {
    height             : 42px;
    width              : 42px;
    position           : absolute;
    background-image   : url(./assets/icons/stopwatch.svg);
    z-index            : 9;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 24px;
    cursor             : pointer;
    bottom             : 0px;
}


/* Select */
.MuiInputBase-root {
    width           : 100%;
    outline         : none;
    background-color: transparent;
    box-sizing      : border-box;
    display         : inline-block;
    font-size       : inherit;
    border          : 0px solid var(--primary-color);
    border-radius   : 5px;
    background-color: #EFF7FA;
    font-family     : inherit;
    padding         : 5px 12px;

}

.MuiInput-underline.Mui-focused:before,
.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom: 0px solid white !important;
}



/* Radio */
.MuiSelect-root {
    font-family: "Montserrat" !important;
}

.big_radio {
    width              : 100%;
    display            : inline-block;
    position           : relative;
    text-align         : center;
    margin-right       : 5px;
    cursor             : pointer;
    transition         : all 0.2s ease;
    background-repeat  : no-repeat;
    background-size    : 50%;
    background-position: center;

    border-radius: 5px;
}


.big_radio.checked,
.big_radio:hover {
    background-color: #EFF7FA;
}

.big_radio:hover strong {
    display: inline-block;
    opacity: 1;
}

.big_radio:before {
    padding-bottom: 100%;
    content       : "";
    position      : relative;
    display       : block;
    height        : 100%;
    transition    : 0.2s ease all;
}

.big_radio strong {
    position        : absolute;
    top             : calc(100% + 10px);
    left            : 50%;
    transform       : translateX(-50%);
    z-index         : 99;
    padding         : 5px 10px;
    background-color: #EFF7FA;
    display         : none;
    border-radius   : 5px;
    opacity         : 0;
    transition      : all 0.2s ease;
}


/* Datepicker */

.datepicker_range {
    width   : 100%;
    position: relative;
}

.datepicker_range .rdt {
    width   : 50%;
    display : inline-block;
    position: absolute !important;
}

.datepicker_range .rdt:nth-child(2) {
    left: 50%;
}

.datepicker_range .rdt:nth-child(2) .rdtPicker {
    left: -100%;
}

.datepicker_range input {
    background-color: transparent !important;
    border          : none !important;
    opacity         : 1 !important;
    border-radius   : 0px;
    border-bottom   : 1px solid #142945 !important;
}


/* Buttons */

.button {
    display                   : inline-block;
    cursor                    : pointer;
    border-bottom-right-radius: 0px;
    background-color          : var(--primary-color);
    color                     : #FFFFFF;
    padding                   : 14px 31px;
    transition                : 0.2s ease all;
    border                    : 0px;
    font-size                 : inherit;
    font-family               : inherit;
    font-weight               : 800;
    border-radius             : 7px;
    background-position       : left +12px center;
}

.button.go_back {
    margin-bottom: 20px;
}

.button.save {
    margin-top: 20px;
}

.button:hover {
    box-shadow     : 0px 3px 6px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color          : white;
}

.button.success {
    background-image   : url(./assets/icons/success.png);
    background-color   : #8BBA62;
    padding-left       : 45px;
    background-position: left +12px center;
    background-size    : 18px;
    background-repeat  : no-repeat;
    pointer-events     : none;
}

.button.loading {
    opacity       : 0.8;
    pointer-events: none;
}

.button.nobackground,
.button.nobackground:hover {
    background-color: transparent;
    box-shadow      : 0px 0px transparent !important;
    color           : var(--text-color);
}

.go_back_button {
    color        : #142945;
    font-weight  : 600;
    padding-left : 30px;
    margin-left  : -5px;
    position     : relative;
    transition   : all 0.2s ease;
    display      : inline-block;
    margin-bottom: 20px;
}

.go_back_button:hover {
    text-decoration: none;
    color          : var(--primary-color);
}

.go_back_button:before {
    content            : '';
    width              : 22px;
    height             : 22px;
    display            : block;
    position           : absolute;
    left               : 0px;
    top                : 0px;
    transition         : all 0.2s ease;
    background-image   : url(./assets/icons/back.svg);
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : contain;
}

.align_right_desktop {
    text-align: right;
}

.loading_container {
    width           : 100%;
    height          : 100vh;
    position        : fixed;
    z-index         : 9999;
    background-color: rgba(255, 255, 255, 0.8);
    display         : flex;
    justify-content : center;
    align-items     : center;
    top             : 0px;
    left            : 0px;
}

.loading_container.relative {
    width           : 100%;
    height          : 50vh;
    position        : relative;
    z-index         : 9999;
    background-color: transparent;
    display         : flex;
    justify-content : center;
    align-items     : center;
    top             : auto;
    left            : auto;
}


.loadingSpinner {
    color: var(--primary-color) !important;
}

.button .loadingSpinner {
    color: white !important;
}

.checkbox_simple {
    color: #142945;
}

.switch {
    display         : inline-block;
    background-color: transparent;
    padding         : 14px 31px;
    cursor          : pointer;
    transition      : all 0.2s ease;
    margin-right    : 10px;
    font-weight     : 800;
    border-radius   : 5px;
}

.switch.selected {
    background-color: #EFF7FA;
}

.root:hover,
.switch:hover {
    background-color: #EFF7FA
}

.icon {
    border-radius   : 3;
    width           : 16;
    height          : 16;
    box-shadow      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
    background-color: '#f5f8fa';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))';
}

.root.Mui-focusVisible {
    outline       : '2px auto rgba(19,124,189,.6)';
    outline-offset: 2;
}

.checkedIcon {
    background-color: '#137cbd';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))';
}

.checkedIcon:before {
    display: 'block';
    width  : 16;
    height : 16;
    content: '';
}

.checkedIcon input:hover {
    background-color: '#106ba3',
}

.MuiTouchRipple-root {
    display: none !important;
}

.MuiCheckbox-root {
    padding      : 0px !important;
    margin       : 0px !important;
    padding-right: 10px !important;
}

.MuiCheckbox-root:hover {
    background-color: transparent !important;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label {
    background-image   : url(./assets/icons/check.png);
    background-position: center 4px;
    background-repeat  : no-repeat;
    background-size    : 10px;
}

.MuiCheckbox-root .MuiIconButton-label {
    border-radius: 5px;
    border       : 1px solid #142945;
    width        : 17px;
    height       : 17px;
}

.MuiFormControlLabel-root {
    margin-left: 0px !important;
}

.MuiFormControlLabel-label {
    font-family: inherit !important;
}

.disabled {
    opacity       : 0.3;
    pointer-events: none;
}

.upload_image_button {
    width              : 100%;
    max-width          : 300px;
    background-color   : #E7EBF0;
    border-radius      : 10px 10px 0px 10px;
    background-image   : url(./assets/icons/upload_image.png);
    background-position: top 30% center;
    background-size    : 50%;
    background-repeat  : no-repeat;
    position           : relative;
    cursor             : pointer;
    transition         : 0.2s ease all;
}

.upload_image_button:before {
    padding-bottom: 120%;
    content       : '';
    display       : block;
}

.upload_image_button:after {
    content    : 'Upload article image';
    display    : block;
    width      : 100%;
    position   : absolute;
    bottom     : 30px;
    font-weight: 900;
    text-align : center;
}

.upload_image_button:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}


/* Table */

.MuiTableRow-root {
    background-color: #FFFFFF;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
    background-color: #F8FAFD;
}

.MuiTableHead-root .MuiTableCell-root {
    font-weight: 800;
}

.MuiTableCell-root {
    border-bottom: none !important;
    font-family  : inherit !important;
}


.MuiTableRow-root.no_background,
.MuiTableBody-root .MuiTableRow-root.no_background:nth-child(odd) {
    background-color: transparent;
    border-top      : 1px solid #E7EBF0;
}

.MuiTableRow-root.no_background:first-child {
    border-top: none;
}



/* Notifications */

.notification_container {
    width              : 500px;
    display            : inline-flex;
    min-height         : 50px;
    padding            : 10px;
    padding-left       : 44px;
    padding-right      : 84px;
    max-width          : 90%;
    color              : #EE4042;
    background-color   : #FFDFE2;
    background-image   : url(./assets/icons/error.svg);
    background-repeat  : no-repeat;
    background-size    : 14px;
    background-position: left 18px center;
    align-items        : center;
    position           : fixed;
    bottom             : 20px;
    right              : 20px;
    z-index            : 99;
    border-radius      : 5px;
    min-height         : 66px;
}

.notification_container.success {
    background-image: url(./assets/icons/success.png);
    background-color: #8BBA62;
    color           : white;
}

.notification_container.success .notification_button {
    background-color: #669F34
}

.notification_close_button {
    width              : 30px;
    height             : 30px;
    position           : absolute;
    right              : 10px;
    top                : 10px;
    background-image   : url(./assets/icons/x.png);
    background-repeat  : no-repeat;
    background-size    : 20px;
    background-position: center;
    cursor             : pointer;
}

.notification_button {
    padding         : 8px 20px;
    background-color: #EE4042;
    color           : white;
    border-radius   : 5px;
    float           : right;
    position        : absolute;
    right           : 15px;
    cursor          : pointer;
}

.popup_close_button_overlay {
    width           : 100vw;
    height          : 100vh;
    background-color: rgba(0, 0, 0, 0.76);
    display         : inline-flex;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    justify-content : center;
    align-items     : center;
    z-index         : 9999;
    overflow        : scroll;
}

.popup_container {
    display         : inline-block;
    background-color: #FFFFFF;
    padding         : 30px;
    width           : 90%;
    max-width       : 700px;
    z-index         : 99999;
    position        : relative;
}

.popup_close_button {
    width              : 30px;
    height             : 30px;
    position           : absolute;
    right              : 20px;
    top                : 20px;
    background-image   : url(./assets/icons/x_dark.png);
    background-repeat  : no-repeat;
    background-size    : 20px;
    background-position: center;
    cursor             : pointer;
}

.small_button {
    display: inline-block;
    cursor : pointer;
    color  : var(--primary-color);
}

.editor {
    background-color: #F8FAFD;
    padding         : 10px;
}

/* accordion */

.accordion {
    width  : 100%;
    display: inline-block;
}

.accordion_title {
    width   : 100%;
    display : inline-block;
    margin  : 10px 0px;
    cursor  : pointer;
    position: relative;
}

.accordion.expanded .accordion_title:before {}

.accordion_arrow {
    width              : 22px;
    height             : 22px;
    background-image   : url(./assets/icons/arrow_down.png);
    background-repeat  : no-repeat;
    background-size    : 13px;
    background-position: center;
    display            : inline-block;
    position           : absolute;
    top                : 0px;
    right              : 0px;
    transition         : 0.2s ease transform;
}

.accordion.expanded>.accordion_title .accordion_arrow {
    transform: rotate(180deg);
}

.ce-block__content {
    max-width: calc(100% - 70px);
}

.ce-toolbar__content {
    max-width: calc(100% - 70px);
}



.article_comment_container {
    display         : block;
    background-color: #F8FAFD;
    border-radius   : 10px 10px 0px 10px;
    margin-bottom   : 10px;
    padding         : 20px;
}

.article_comment_author {
    display      : block;
    text-align   : right;
    margin-top   : 5px;
    margin-bottom: 20px;
}

.article_comment_container.reply {
    margin-left: 20px;
}


.card {
    width           : 100%;
    height          : 100%;
    border-radius   : 10px;
    background-color: #F8FAFD;
    border          : 0px;
    padding-top     : 15px;
    padding-bottom  : 15px;
}

.mobile_header {
    display : none;
    height  : 40px;
    width   : 100%;
    padding : 20px;
    position: fixed;
    z-index : 99999999;
}

.burger_left {
    cursor  : pointer;
    display : none;
    position: relative;
    height  : 24px;
    width   : 30px;
    top     : 20px;
    left    : 20px;
    z-index : 999999999999;
}

.burger_left .burder_line {
    position  : absolute;
    top       : 0px;
    left      : 0px;
    transition: all 0.2s ease;
}

.burger_left .burder_line:nth-child(1) {
    top: 0px
}

.burger_left .burder_line:nth-child(2) {
    top: 9px
}

.burger_left .burder_line:nth-child(3) {
    top: 18px
}

.burger_right {
    background-image   : url('./assets/icons/arrow_down.png');
    background-repeat  : no-repeat;
    background-size    : 15px;
    background-position: center;
    transform          : rotate(90deg);
    width              : 30px;
    height             : 24px;
    display            : none;
    position           : fixed;
    top                : 20px;
    right              : 30px;
    cursor             : pointer;
    z-index            : 99999999;
    transition         : transform 0.2s ease;
}




.burger_left.open .burder_line:nth-child(1) {
    top      : 7px;
    transform: rotate(-45deg);
}

.burger_left.open .burder_line:nth-child(2) {
    display: none;
}

.burger_left.open .burder_line:nth-child(3) {
    top      : 7px;
    transform: rotate(45deg);
}

.burder_line {
    width           : 30px;
    border-radius   : 40px;
    height          : 4px;
    background-color: var(--primary-color);
    display         : block;
    margin-bottom   : 4px;
}


.middle_container {
    width      : calc(100% - 280px);
    margin-left: 0px;
}


.task_container {
    position  : relative;
    width     : 100%;
    padding   : 20px;
    display   : inline-block;
    min-height: 63px;
}

.task_wrapper {
    width        : 100%;
    padding      : 0px;
    display      : inline-block;
    border       : 2px solid #EFF7FA;
    border-radius: 5px;
    margin-bottom: 10px;
}

.task_container_subtask_wrapper {
    width       : 100%;
    padding-left: 30px;
}

.task_container_subtask {
    width   : 100%;
    padding : 20px;
    display : inline-block;
    position: relative
}

.task_sidebar {
    position        : fixed;
    height          : 100vh;
    background-color: white;
    box-shadow      : 0px 0px 20px rgba(0, 0, 0, 0.06);
    padding         : 40px;
    top             : 0px;
    right           : 0px;
    width           : calc(50vw - 200px) !important;
    z-index         : 99;
    overflow        : scroll;
}


.add_button,
.edit_button,
.remove_button,
.icon_button {
    width              : 40px;
    height             : 40px;
    display            : inline-block;
    background-color   : transparent;
    background-size    : 20px;
    background-repeat  : no-repeat;
    background-position: center;
    cursor             : pointer;
    position           : absolute;
    transition         : 0.2s ease all;
    border-radius      : 5px;
    z-index            : 9;
}

.add_button:hover,
.edit_button:hover,
.remove_button:hover,
.icon_button:hover {
    background-color: #EFF7FA;
}

.edit_button {
    background-image: url('./assets/icons/edit.svg');
    right           : 50px;
    top             : 11px;
}

.add_button {
    background-image: url('./assets/icons/add.svg');
    right           : 10px;
    top             : 11px;
}

.remove_button {
    background-image: url('./assets/icons/remove.svg');
    right           : 10px;
    top             : 11px;
}

.download_button {
    background-image: url('./assets/icons/download.svg');
    right           : 10px;
    top             : 11px;
}

.timetracking_task_wrapper {
    width         : calc(33.3333% - 2px);
    display       : inline-block;
    vertical-align: top;
    margin-right  : 2px;
    border-right  : 1px solid #EFF7FA;
    padding       : 10px;
}

.timetracking_task_container {
    width        : 100%;
    padding      : 15px;
    box-sizing   : border-box;
    display      : block;
    cursor       : pointer;
    margin-bottom: 2px;
    border-radius: 5px;
}

.timetracking_task_wrapper:nth-child(3n) {
    margin-right: 0px;
}

.timetracking_task_container:hover,
.timetracking_task_container.selected {
    background-color: #EFF7FA;
}


@media (max-width: 1300px) {

    .sidebar {
        width      : 310px;
        max-width  : 100vw;
        height     : 100vh;
        position   : fixed;
        margin-left: -310px;
        transition : margin 0.5s ease;
        z-index    : 999999999;
    }

    .sidebar.open {
        margin-left: 0px;
    }

    .sidebar_right {
        margin-right: -100%;
        width       : 100%;
    }

    .sidebar_right.open {
        margin-right: 0px;
    }

    .burger_right {
        display: block;
    }

    .burger_right.open {
        transform: rotate(-90deg);
    }

    .content_wrapper {
        width      : 100%;
        display    : inline-block;
        position   : absolute;
        top        : 0px;
        left       : 0px;
        padding    : 0px;
        padding-top: 60px;
    }

    .burger_left {
        position: fixed;
        top     : 30px;
        left    : 30px;
        display : inline-block;
    }

    .row>div {
        margin-bottom: 30px;
    }

    .mobile_header {
        display: block;
    }

    .middle_container {
        width: 100%;
    }

    .go_back_button {
        top : 20px;
        left: 20px;
    }

}

@media (max-width: 767px) {
    .align_right_desktop {
        text-align: left;
    }
}



/* TIMELINE RESOURCE PLANNER */

.timeline_item {
    background-color: var(--primary-color) !important;
    border          : 0px !important;
    border-radius   : 20px;
    transition      : shadow 0.2s ease;
}

.timeline_item_content {
    padding-left: 10px;
    transition  : 0.2s ease padding;
}

.timeline_item.selected .timeline_item_content {
    padding-left: 25px;
}

.timeline_item.selected {
    /* background-color: red !important; */
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.16);
}

.timeline_item .timeline_resize_handler {
    height    : 14px !important;
    margin-top: 3px;
    opacity   : 0;
    transition: 0.2s ease all;
    width     : 24px;
    max-width : 24px;
    min-width : 24px;
}

.timeline_item.selected .timeline_resize_handler {
    opacity: 1;
}

.timeline_item .timeline_resize_handler * {
    stroke: white !important;
}

.rct-header-root {
    background-color: #EFF7FA !important;
    pointer-events  : none !important;
    border          : 0px !important;
}

.rct-hl-even,
.rct-sidebar-row-even {
    border-bottom: 0px !important;
}

.rct-sidebar {
    box-shadow  : 0px 0px 5px rgba(0, 0, 0, 0.16) !important;
    z-index     : 9;
    border-right: 0px !important;
}

.rct-hl-odd,
.rct-sidebar-row-odd {
    background-color: #EFF7FA !important;
    border-bottom   : 0px !important;
}

.rct-vertical-lines .rct-vl {
    border-left: 1px solid #e4e8ee !important
}

.rct-dateHeader {
    border-bottom   : 0px !important;
    border-left     : 1px solid #e4e8ee !important;
    border-right    : 0px !important;
    background-color: #EFF7FA !important;
    color           : black !important;
}

.react-calendar-timeline .rct-calendar-header {
    border: 0px !important;
}

.rct-day-6,
.rct-day-0 {
    background-color: lightcoral !important;
    opacity         : 0.2;
}